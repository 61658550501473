<template>

    <div class="page-content">
        <div class="container-fluid">

            <Breadcrumb
                :pgTitle="$t('nav.coupons')"
                :pgIcon="'bx bx-money'"
                :refs="refs"
                :pgCurrent="pgCurrent"
                :addNew="false">
            </Breadcrumb>

            <div class="row">

                <Loading
                    v-if="pgLoading">
                </Loading>

                <div v-if="!pgLoading" class="col-8">

                    <FormElements
                        :label="$t('view.coupon_info')"
                        :labelTitle="$t('view.coupon_title')"
                        :labelBody="$t('view.coupon_body')"
                        
                        :hasTitle=true
                        :title_ar="row.title_ar"
                        :title_en="row.title_en"
                        
                        :hasBody=false
                        
                        v-on:formChange="handleFormChange">
                    </FormElements>

                </div>

                <div v-if="!pgLoading" class="col-4">

                    <CardImage
                        :labelImage="$t('view.offer_image')"
                        :image_base64="row.image_baes64"
                        :image_preview="row.image_preview"
                        v-on:imageChange="handleImageChange">
                    </CardImage>

                    <CardLecturer
                        v-if="auth.role == 'root'"
                        :lecturer_id="row.lecturer_id"
                        v-on:lecturerChange="handleLecturerChange">
                    </CardLecturer>

                    <CardModule
                        v-if="row.lecturer_id"
                        :model_type="row.model_type"
                        :model_id="row.model_id"
                        :lecturer_id="row.lecturer_id"
                        v-on:selectChange="handleModuleChange">
                    </CardModule>

                    <CardInput
                        :label="$t('app.couponCount')"
                        :input="row.count"
                        :type="'number'"
                        :disabled="disabled"
                        v-on:inputChange="handleCountChange">
                    </CardInput>

                    <!-- <CardInput
                        :label="$t('app.cost')"
                        :input="row.value"
                        :type="'number'"
                        v-on:inputChange="handlePriceChange">
                    </CardInput> -->

                    <CardInput
                        :label="$t('app.percentage')"
                        :input="row.percentage"
                        :type="'number'"
                        :disabled="disabled"
                        v-on:inputChange="handlePercentageChange">
                    </CardInput>

                    <CardStatus
                        :status="row.status"
                        v-on:statusChange="handleStatusChange">
                    </CardStatus>
                
                </div>
            </div>


            <Buttons
                v-if="!pgLoading"
                :btnLoading="btnLoading"
                :btnCurrent="btnCurrent"
                        
                v-on:submitClicked="handelSubmit"
                v-on:cancelClicked="handleCancel">
            </Buttons>

        </div>
    </div>

</template>

<script>
import iziToast from 'izitoast';

export default {
    name: 'EditOrNew',
    components: {
        Breadcrumb: () => import('@/components/Breadcrumb.vue'),
        Loading: () => import('@/components/Loading.vue'),
        FormElements: () => import('@/components/FormElements.vue'),
        CardLecturer: () => import('@/components/CardLecturer.vue'),
        CardImage: () => import('@/components/CardImage.vue'),
        CardModule: () => import('@/components/CardModule.vue'),
        CardInput: () => import('@/components/CardInput.vue'),
        CardStatus: () => import('@/components/CardStatus.vue'),
        Buttons: () => import('@/components/Buttons.vue')
    },
    data(){
        return {    
            // auth
            auth: {
                role: '',
                access_token: ''
            },

            // row
            row: {
                title_ar: '',
                title_en: '',

                value: '0.00',
                percentage: 0,
                model_type: '',
                model_id: '',
                count: 1,

                image_base64: '',
                image_preview: '',

                lecturer_id: '',

                status: 1,
            },
                
            msgCurrent: (this.$route.params.id) ? 'Updated' : 'Created',
            btnCurrent: (this.$route.params.id) ? 'Update' : 'Create',
            methodType: (this.$route.params.id) ? 'PUT' : 'POST',
            pgLoading: (this.$route.params.id) ? true : false,
            pgCurrent: (this.$route.params.id) ? 'Edit' : 'Add New',
            btnLoading: false,
            
            disabled: (this.$route.params.id) ? true : false,
            refs: 'coupons',
        }
    },
    mounted() {},
    created() {
        // AccessToken & Role
        if(localStorage.getItem('access_token')) {
            this.auth.access_token = localStorage.getItem('access_token');
        }
        if(localStorage.getItem('role')) {
            this.auth.role = localStorage.getItem('role');
            if (this.auth.role == 'lecturer' && localStorage.getItem('lecturer_id')) {
                this.row.lecturer_id = localStorage.getItem('lecturer_id');
            }
        }

        if(this.$route.params.id) {
            this.fetchRow();
        }
    },
    methods: {
        
        // fetch Row
        fetchRow() {
            this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/'+this.refs+'/'+this.$route.params.id,
                method: 'GET',
                data: {}
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;

                this.row.image_preview = (res.data.row.image) ? res.data.row.image.url : '';
                this.row.image_base64 = (res.data.row.image) ? res.data.row.image.url : '';

                this.row.title_ar = res.data.row.ar.title;
                this.row.title_en = res.data.row.en.title;

                //this.row.value = res.data.row.value;
                this.row.model_type = res.data.row.model_type;
                this.row.model_id = res.data.row.model_id;
                this.row.percentage = res.data.row.percentage;
                this.row.count = res.data.row.count;
                
                this.row.lecturer_id = res.data.row.lecturer_id;
                this.row.status = res.data.row.status;
            })
            .catch(err => {

                // 403 Forbidden
                if(err.response && err.response.status == 401) {
                    this.clearLocalStorage();
                    this.$router.push({ name: 'login' });
                } else if(err.response && err.response.status == 403) {
                    this.$router.push({ name: 'forbidden' });
                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'bx bx-angry',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }

            })
            .finally(() => {});
        },
            

        // handle edit Or New submit
        handelSubmit(){
            this.btnLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };

            const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
            const options = {
                url: (this.$route.params.id) ? window.baseURL+'/'+this.refs+'/'+this.$route.params.id : window.baseURL+'/'+this.refs,
                method: this.methodType,
                data: {

                    ar: {
                        title: this.row.title_ar
                    },

                    en: {
                        title: this.row.title_en
                    },

                    //value: this.row.value,
                    model_type: this.row.model_type,
                    model_id: this.row.model_id,
                    percentage: this.row.percentage,
                    count: this.row.count,
                    image_base64: this.row.image_base64,

                    lecturer_id: this.row.lecturer_id,
                    status: this.row.status
                }
            }
            this.axios(options, config)
            .then(() => {
                this.btnLoading = false;
                iziToast.success({
                    icon: 'bx bx-wink-smile',
                    title: '',
                    message: (this.btnCurrent == 'Update') ? 'تم التعديل بنجاح' : 'تم الإضافة بنجاح'
                });
                    
                this.$router.push({ name: this.refs });
            })
            .catch(err => {
                        
                // 403 Forbidden
                if(err.response && err.response.status == 401) {
                    this.clearLocalStorage();
                    this.$router.push({ name: 'login' });
                } else if(err.response && err.response.status == 403) {
                    this.$router.push({ name: 'forbidden' });
                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'bx bx-angry',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }
            })
            .finally(() => {})
        },

          
        // clear Local Storage
        clearLocalStorage() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('avatar');
            localStorage.removeItem('username');
            localStorage.removeItem('encrypt_id');
            localStorage.removeItem('role');
        },


        // Cancel
        handleCancel(){
            if(confirm('هل انت متأكد؟')) {
                this.$router.push({ name: this.refs });
            }
        },


        // handel Changes from child components
        handleFormChange(event) {
            this.row.title_ar = event.title_ar;
            this.row.title_en = event.title_en;
        },

        handleModuleChange(event) {
            this.row.model_type = event.model_type;
            this.row.model_id = event.model_id;
        },

        handleImageChange(event) {
            this.row.image_base64 = event.image_base64;
        },

        handleLecturerChange(event) {
            this.row.lecturer_id = event.input;
        },

        // handlePriceChange(event) {
        //     this.row.value = event.input;
        // },

        handlePercentageChange(event) {
            this.row.percentage = event.input;
        },

        handleCountChange(event) {
            this.row.count = event.input;
        },

        handleStatusChange(event) {
            this.row.status = event.status;
        },

    },
}
</script>